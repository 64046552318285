

// import React, { useState, useEffect } from 'react';
// import { Link, useNavigate, useLocation } from 'react-router-dom';
// import './styles2.css';
// import MetricsPage from './MetricsPage';

// const API_URL = process.env.REACT_APP_API_URL;

// function EC2ControlPanel({ username, password }) {
//   const [output, setOutput] = useState('');
//   const [iamCredentials, setIamCredentials] = useState(null);
//   const [iamError, setIamError] = useState(null);
//   const [instanceState, setInstanceState] = useState('');
//   const [loading, setLoading] = useState(false);
//   const [actionInProgress, setActionInProgress] = useState('');
//   const [labsAccess, setLabsAccess] = useState(false);
//   const [rdpAccess, setRdpAccess] = useState(false);
//   const [iamAccess, setIamAccess] = useState(false);
//   const [accessError, setAccessError] = useState(null);

//   const navigate = useNavigate();
//   const location = useLocation();

//   useEffect(() => {
//     navigate(location.pathname, { replace: true });
//     const handlePopState = () => {
//       navigate(location.pathname, { replace: true });
//     };

//     window.addEventListener('popstate', handlePopState);

//     const handleBeforeUnload = (e) => {
//       e.preventDefault();
//       e.returnValue = '';
//     };
//     window.addEventListener('beforeunload', handleBeforeUnload);

//     return () => {
//       window.removeEventListener('popstate', handlePopState);
//       window.removeEventListener('beforeunload', handleBeforeUnload);
//     };
//   }, [navigate, location]);

//   const fetchData = async (url, actionType) => {
//     try {
//       if (['startInstance', 'rebootInstance', 'stopInstance', 'getIamCreds'].includes(actionType)) {
//         setLoading(true);
//       }
//       const response = await fetch(url);
//       if (!response.ok) {
//         throw new Error(`HTTP error! status: ${response.status}`);
//       }
//       const data = await response.json();
//       setInstanceState(data.state);
//       displayOutput(data);
//       if (['Starting', 'Stopping', 'stopping'].includes(data.state)) {
//         setTimeout(getInstanceInfo, 5000);
//       }
//     } catch (error) {
//       console.error('Error fetching data:', error);
//       setOutput(`Error: ${error.message}`);
//     } finally {
//       if (['startInstance', 'rebootInstance', 'stopInstance', 'getIamCreds'].includes(actionType)) {
//         setLoading(false);
//       }
//       setActionInProgress('');
//     }
//   };

//   const startInstance = () => {
//     const url = `${API_URL}/StartInstance?username=${username}&password=${password}`;
//     fetchData(url, 'startInstance');
//   };

//   const rebootInstance = () => {
//     const url = `${API_URL}/RestartInstance?username=${username}`;
//     fetchData(url, 'rebootInstance');
//   };

//   const stopInstance = () => {
//     const url = `${API_URL}/StopInstance?username=${username}`;
//     fetchData(url, 'stopInstance');
//   };

//   const getInstanceInfo = () => {
//     const url = `${API_URL}/GetInstanceInfo?username=${username}`;
//     fetchData(url, 'getInstanceInfo');
//   };

//   const generateAuthToken = async () => {
//     try {
//       const guacUrl = process.env.REACT_APP_GUAC_URL;
//       const tokenUrl = process.env.REACT_APP_GUAC_TOKEN;

//       const response = await fetch(tokenUrl, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/x-www-form-urlencoded',
//         },
//         body: `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`,
//       });

//       if (!response.ok) {
//         const errorText = await response.text();
//         throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
//       }

//       const data = await response.json();
//       const authToken = data.authToken;

//       if (authToken) {
//         const clientUrl = `${guacUrl}#/?token=${authToken}`;
//         const rdpWindow = window.open(clientUrl, '_blank', 'width=1024,height=768');
//         if (rdpWindow) {
//           rdpWindow.onload = () => {
//             rdpWindow.history.pushState(null, '', clientUrl);
//             rdpWindow.addEventListener('popstate', (event) => {
//               event.preventDefault();
//               rdpWindow.history.pushState(null, '', clientUrl);
//             });
//           };
//         }
//       } else {
//         throw new Error('No auth token received');
//       }
//     } catch (error) {
//       console.error('Error generating auth token:', error);
//       setOutput(`Error: ${error.message}`);
//     }
//   };

//   const requestIamCredentials = async () => {
//     try {
//       setLoading(true);
//       const response = await fetch(`${API_URL}/GenIamCreds?username=${username}`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           'Accept': 'application/json',
//         },
//         body: JSON.stringify({ queryStringParameters: { username } }),
//       });

//       if (!response.ok) {
//         const errorText = await response.text();
//         throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
//       }

//       const data = await response.json();
//       setIamCredentials(data);
//       setIamError(null);
//     } catch (error) {
//       console.error('Error requesting IAM credentials:', error);
//       setIamError(`Error requesting IAM credentials: ${error.message}`);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const displayOutput = (data) => {
//     if (!data || !data.username) {
//       setOutput(`<div class="output-info"><p>Instance has not been allocated.</p></div>`);
//       return;
//     }

//     let formattedData = `
//       <div class="output-info">
//         <p><strong>Username:</strong> ${data.username}</p>
//         <p><strong>Instance IP:</strong> ${data.public_ip || 'N/A'}</p>
//         <p><strong>Instance ID:</strong> ${data.instanceId || 'N/A'}</p>
//         <p><strong>State:</strong> ${data.state || 'N/A'}</p>
//       </div>
//     `;

//     setOutput(formattedData);

//     if (['Starting', 'Stopping', 'stopping'].includes(data.state)) {
//       setTimeout(getInstanceInfo, 5000);
//     }
//   };

//   useEffect(() => {
//     const fetchAccessControl = async () => {
//       try {
//         setLoading(true);
//         const response = await fetch(`${API_URL}/AccessControl?username=${username}`);
//         if (!response.ok) {
//           const errorText = await response.text();
//           throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
//         }
//         const data = await response.json();
//         console.log('Access Control Data:', data); // Debug output
//         setLabsAccess(data.labs_access);
//         setRdpAccess(data.rdp_access);
//         setIamAccess(data.iam_access);
//         setAccessError(null);
//       } catch (error) {
//         console.error('Error fetching access control:', error);
//         setAccessError(`Error fetching access control: ${error.message}`);
//       } finally {
//         setLoading(false);
//       }
//     };
  
//     fetchAccessControl();
//     getInstanceInfo();
//   }, [username]);

//   const handleLinkClick = (event, hasAccess) => {
//     if (!hasAccess) {
//       event.preventDefault();
//     }
//   };

//   return (
//     <div className="container">
//       <div className="control-panel">
//         <h1 className="panel-title"></h1>
//         {accessError && <p className="access-error">{accessError}</p>}
//         <div className="control-buttons">
//           <button onClick={generateAuthToken} className="control-btn" disabled={!rdpAccess || instanceState !== 'running'}>Connect</button>
//           <button onClick={startInstance} className="control-btn" disabled={!rdpAccess}>Start Instance</button>
//           <button onClick={rebootInstance} className="control-btn" disabled={!rdpAccess}>Reboot Instance</button>
//           <button onClick={stopInstance} className="control-btn" disabled={!rdpAccess}>Stop Instance</button>
//           <button onClick={getInstanceInfo} className="control-btn" disabled={!rdpAccess}>Get Instance Info</button>
          
//           <Link 
//             to="/report" 
//             className={`control-btn link-btn ${!rdpAccess ? 'disabled-link' : ''}`} 
//             onClick={(event) => handleLinkClick(event, rdpAccess)}
//           >
//             Report
//           </Link>
//           <Link 
//             to="/MetricsPage" 
//             className={`control-btn link-btn ${!rdpAccess ? 'disabled-link' : ''}`} 
//             onClick={(event) => handleLinkClick(event, labsAccess)}
//           >
//             Metrics
//           </Link>
//           <Link 
//             to="/labs" 
//             className={`control-btn link-btn ${!labsAccess ? 'disabled-link' : ''}`} 
//             onClick={(event) => handleLinkClick(event, labsAccess)}
//           >
//             Labs
//           </Link>
          
//           <button onClick={requestIamCredentials} className="control-btn" disabled={!iamAccess}>Request IAM Credentials</button>
//         </div>
//         <div className="output-block">
//           {rdpAccess && output && <div dangerouslySetInnerHTML={{ __html: output }} />}
//           {iamCredentials && (
//             <div className="iam-credentials">
//               <h3>Sign in:
              
//                 <a href={`https://${iamCredentials.account_number}.signin.aws.amazon.com/console`} target="_blank" rel="noopener noreferrer">
//                   AWS Console
//                 </a></h3>
              
//               <h4><strong>Username:</strong> {iamCredentials.username}</h4>
//               <h4><strong>Password:</strong> {iamCredentials.password}</h4>
//               <h4><strong>Access Key:</strong> {iamCredentials.access_key_id}</h4>
//               <h4><strong>Secret Access Key:</strong> {iamCredentials.secret_access_key}</h4>
//             </div>
//           )}
//         </div>
//         {loading && <div className="loading-overlay"><div className="spinner"></div>Loading...</div>}
//         {iamError && <p className="error-message">{iamError}</p>}
//       </div>
//     </div>
//   );
// }

// export default EC2ControlPanel;


import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './styles2.css';
import MetricsPage from './MetricsPage';

const API_URL = process.env.REACT_APP_API_URL;

function EC2ControlPanel({ username, password }) {
  const [output, setOutput] = useState('');
  const [iamCredentials, setIamCredentials] = useState(null);
  const [iamError, setIamError] = useState(null);
  const [instanceState, setInstanceState] = useState('');
  const [loading, setLoading] = useState(false);
  const [actionInProgress, setActionInProgress] = useState('');
  const [labsAccess, setLabsAccess] = useState(false);
  const [rdpAccess, setRdpAccess] = useState(false);
  const [iamAccess, setIamAccess] = useState(false);
  const [awsAccess, setAwsAccess] = useState(false);
  const [gcpAccess, setGcpAccess] = useState(false);
  const [azureAccess, setAzureAccess] = useState(false);
  const [accessError, setAccessError] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    navigate(location.pathname, { replace: true });
    const handlePopState = () => {
      navigate(location.pathname, { replace: true });
    };

    window.addEventListener('popstate', handlePopState);

    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = '';
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('popstate', handlePopState);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [navigate, location]);

  const fetchData = async (url, actionType) => {
    try {
      if (['startInstance', 'rebootInstance', 'stopInstance', 'getIamCreds'].includes(actionType)) {
        setLoading(true);
      }
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setInstanceState(data.state);
      displayOutput(data);
      if (['Starting', 'Stopping', 'stopping'].includes(data.state)) {
        setTimeout(getInstanceInfo, 5000);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setOutput(`Error: ${error.message}`);
    } finally {
      if (['startInstance', 'rebootInstance', 'stopInstance', 'getIamCreds'].includes(actionType)) {
        setLoading(false);
      }
      setActionInProgress('');
    }
  };

  const startInstance = () => {
    const url = `${API_URL}/StartInstance?username=${username}&password=${password}`;
    fetchData(url, 'startInstance');
  };

  const rebootInstance = () => {
    const url = `${API_URL}/RestartInstance?username=${username}`;
    fetchData(url, 'rebootInstance');
  };

  const stopInstance = () => {
    const url = `${API_URL}/StopInstance?username=${username}`;
    fetchData(url, 'stopInstance');
  };

  const getInstanceInfo = () => {
    const url = `${API_URL}/GetInstanceInfo?username=${username}`;
    fetchData(url, 'getInstanceInfo');
  };

  const generateAuthToken = async () => {
    try {
      const guacUrl = process.env.REACT_APP_GUAC_URL;
      const tokenUrl = process.env.REACT_APP_GUAC_TOKEN;

      const response = await fetch(tokenUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`,
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }

      const data = await response.json();
      const authToken = data.authToken;

      if (authToken) {
        const clientUrl = `${guacUrl}#/?token=${authToken}`;
        const rdpWindow = window.open(clientUrl, '_blank', 'width=1024,height=768');
        if (rdpWindow) {
          rdpWindow.onload = () => {
            rdpWindow.history.pushState(null, '', clientUrl);
            rdpWindow.addEventListener('popstate', (event) => {
              event.preventDefault();
              rdpWindow.history.pushState(null, '', clientUrl);
            });
          };
        }
      } else {
        throw new Error('No auth token received');
      }
    } catch (error) {
      console.error('Error generating auth token:', error);
      setOutput(`Error: ${error.message}`);
    }
  };

  const requestIamCredentials = async () => {
    try {
      setLoading(true);
      let response;
      let data;

      if (awsAccess) {
        response = await fetch(`${API_URL}/GenIamCreds?username=${username}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          body: JSON.stringify({ queryStringParameters: { username } }),
        });
      } else if (azureAccess) {
        response = await fetch(`${API_URL}/AzureCreds?username=${username}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          body: JSON.stringify({ username }),
        });
      } else if (gcpAccess) {
        // Do nothing for GCP as per previous instructions
        setLoading(false);
        return;
      } else {
        throw new Error('No cloud platform access available');
      }

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }

      data = await response.json();
      setIamCredentials(data);
      setIamError(null);
    } catch (error) {
      console.error('Error requesting IAM credentials:', error);
      setIamError(`Error requesting IAM credentials: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const displayOutput = (data) => {
    if (!data || !data.username) {
      setOutput(`<div class="output-info"><p>Instance has not been allocated.</p></div>`);
      return;
    }

    let formattedData = `
      <div class="output-info">
        <p><strong>Username:</strong> ${data.username}</p>
        <p><strong>Instance IP:</strong> ${data.public_ip || 'N/A'}</p>
        <p><strong>Instance ID:</strong> ${data.instanceId || 'N/A'}</p>
        <p><strong>State:</strong> ${data.state || 'N/A'}</p>
      </div>
    `;

    setOutput(formattedData);

    if (['Starting', 'Stopping', 'stopping'].includes(data.state)) {
      setTimeout(getInstanceInfo, 5000);
    }
  };

  useEffect(() => {
    const fetchAccessControl = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${API_URL}/AccessControl?username=${username}`);
        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
        }
        const data = await response.json();
        console.log('Access Control Data:', data); // Debug output
        setLabsAccess(data.labs_access);
        setRdpAccess(data.rdp_access);
        setIamAccess(data.iam_access);
        setAwsAccess(data.aws_access);
        setGcpAccess(data.gcp_access);
        setAzureAccess(data.azure_access);
        setAccessError(null);
      } catch (error) {
        console.error('Error fetching access control:', error);
        setAccessError(`Error fetching access control: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };
  
    fetchAccessControl();
    getInstanceInfo();
  }, [username]);

  const handleLinkClick = (event, hasAccess) => {
    if (!hasAccess) {
      event.preventDefault();
    }
  };

  return (
    <div className="container">
      <div className="control-panel">
        <h1 className="panel-title"></h1>
        {accessError && <p className="access-error">{accessError}</p>}
        <div className="control-buttons">
          <button onClick={generateAuthToken} className="control-btn" disabled={!rdpAccess || instanceState !== 'running'}>Connect</button>
          <button onClick={startInstance} className="control-btn" disabled={!rdpAccess}>Start Instance</button>
          <button onClick={rebootInstance} className="control-btn" disabled={!rdpAccess}>Reboot Instance</button>
          <button onClick={stopInstance} className="control-btn" disabled={!rdpAccess}>Stop Instance</button>
          <button onClick={getInstanceInfo} className="control-btn" disabled={!rdpAccess}>Get Instance Info</button>
          
          <Link 
            to="/report" 
            className={`control-btn link-btn ${!rdpAccess ? 'disabled-link' : ''}`} 
            onClick={(event) => handleLinkClick(event, rdpAccess)}
          >
            Report
          </Link>
          <Link 
            to="/MetricsPage" 
            className={`control-btn link-btn ${!rdpAccess ? 'disabled-link' : ''}`} 
            onClick={(event) => handleLinkClick(event, labsAccess)}
          >
            Metrics
          </Link>
          <Link 
            to="/labs" 
            className={`control-btn link-btn ${!labsAccess ? 'disabled-link' : ''}`} 
            onClick={(event) => handleLinkClick(event, labsAccess)}
          >
            Labs
          </Link>
          
          <button onClick={requestIamCredentials} className="control-btn" disabled={!iamAccess}>Request IAM Credentials</button>
        </div>
        <div className="output-block">
          {rdpAccess && output && <div dangerouslySetInnerHTML={{ __html: output }} />}
          {iamCredentials && (
            <div className="iam-credentials">
              {awsAccess && (
                <>
                  <h3>Sign in: 
                    <a href={`https://${iamCredentials.account_number}.signin.aws.amazon.com/console`} target="_blank" rel="noopener noreferrer">
                      AWS Console
                    </a>
                  </h3>
                  <h4><strong>Username:</strong> {iamCredentials.username}</h4>
                  <h4><strong>Password:</strong> {iamCredentials.password}</h4>
                  <h4><strong>Access Key:</strong> {iamCredentials.access_key_id}</h4>
                  <h4><strong>Secret Access Key:</strong> {iamCredentials.secret_access_key}</h4>
                </>
              )}
              {azureAccess && (
                <>
                  <h3>Sign in: 
                    <a href="https://portal.azure.com" target="_blank" rel="noopener noreferrer">
                      Azure Portal
                    </a>
                  </h3>
                  <h4><strong>Email:</strong> {iamCredentials.Email}</h4>
                  <h4><strong>Password:</strong> {iamCredentials.Pass}</h4>
                </>
              )}
            </div>
          )}
        </div>
        {loading && <div className="loading-overlay"><div className="spinner"></div>Loading...</div>}
        {iamError && <p className="error-message">{iamError}</p>}
      </div>
    </div>
  );
}

export default EC2ControlPanel;