import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import './styles7.css';

const API_URL = process.env.REACT_APP_API_URL;
const TASK_API_URL = `${API_URL}/GetLabs`;

function LabDetailPage({ username }) {
    const { labId } = useParams();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const taskNameFromQuery = query.get('taskName');
    
    const [lab, setLab] = useState(null);
    const [taskName, setTaskName] = useState(taskNameFromQuery || 'Loading Lab Name...');
    const [isCheckingProgress, setIsCheckingProgress] = useState(false);
    const [checkProgressDisabled, setCheckProgressDisabled] = useState(false);
    const [labCompleted, setLabCompleted] = useState(false);
    const [iamCredentials, setIamCredentials] = useState(null);
    const [iamError, setIamError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [labStarted, setLabStarted] = useState(false);

    const fetchTaskName = useCallback(async () => {
        if (!taskNameFromQuery) {
            setLoading(true);
            try {
                const response = await fetch(`${TASK_API_URL}?taskId=${labId}`);
                if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
                const data = await response.json();
                
                console.log('API response data:', data);

                if (data && data.taskName) {
                    setTaskName(data.taskName);
                } else {
                    setTaskName('Task Name not available');
                }
            } catch (error) {
                console.error('Error fetching task name:', error);
                setTaskName('Task Name not available');
            } finally {
                setLoading(false);
            }
        }
    }, [taskNameFromQuery, labId]);

    const fetchLabDetails = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetch(`${TASK_API_URL}?taskId=${labId}`);
            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
            const data = await response.json();
            setLab(data);
            if (data.completed) setLabCompleted(true);
        } catch (error) {
            console.error('Error fetching lab details:', error);
            alert('Error fetching lab details: ' + error.message);
        } finally {
            setLoading(false);
        }
    }, [labId]);

    useEffect(() => {
        if (labId) {
            fetchTaskName();
            fetchLabDetails();
        }
    }, [labId, fetchTaskName, fetchLabDetails]);

    const startLab = async () => {
        try {
            const response = await fetch(`${API_URL}/StartLab?username=${username}&taskId=${labId}`);
            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
            fetchLabDetails();
            checkLabProgress();
            requestIamCredentials();
            setLabStarted(true);
        } catch (error) {
            console.error('Error starting lab:', error);
            alert('Error starting lab: ' + error.message);
        }
    };

    const checkLabProgress = async () => {
        setIsCheckingProgress(true);
        setCheckProgressDisabled(true);

        try {
            const response = await fetch(`${API_URL}/CheckLabProgress?username=${username}&taskId=${labId}`);
            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
            const data = await response.json();
            setLab(data);
            if (data.completed) setLabCompleted(true);
        } catch (error) {
            console.error('Error checking lab progress:', error);
            alert('Error checking lab progress: ' + error.message);
        } finally {
            setIsCheckingProgress(false);
            setTimeout(() => setCheckProgressDisabled(false), 30000);
        }
    };

    const requestIamCredentials = async () => {
        try {
            setLoading(true);
            const response = await fetch(`${API_URL}/GenIamCreds?username=${username}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify({ queryStringParameters: { username } }),
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
            }

            const data = await response.json();
            setIamCredentials(data);
            setIamError(null);
        } catch (error) {
            console.error('Error requesting IAM credentials:', error);
            setIamError(`Error requesting IAM credentials: ${error.message}`);
            setIamCredentials(null);
        } finally {
            setLoading(false);
        }
    };

    const isStartLabDisabled = lab && lab.progress === 100;

    return (
        <div className="labs-page">
            <h2>Lab Details</h2>
            <h3>{taskName}</h3>

            <div className="lab-detail-container">
                <button 
                    onClick={startLab} 
                    className="start-lab-btn" 
                    disabled={isStartLabDisabled || loading}
                >
                    Start Lab
                </button>
                <button 
                    onClick={checkLabProgress} 
                    className="check-progress-btn" 
                    disabled={!labStarted || checkProgressDisabled || loading}
                >
                    Check Progress
                </button>
                {isCheckingProgress && <div className="progress-popup">Please wait...</div>}

                {lab && (
                    <>
                        {labCompleted && (
                            <div className="lab-completed">
                                <div className="icon">🎉</div>
                                <h2>Congratulations!</h2>
                                <p>Lab Completed Successfully!</p>
                            </div>
                        )}

                        {iamCredentials && (
                            <div className="iam-credentials">
                                <h3>IAM Credentials:</h3>
                                <p><strong>Account Number:</strong> {iamCredentials.account_number}</p>
                                <p><strong>Username:</strong> {iamCredentials.username}</p>
                                <p><strong>Password:</strong> {iamCredentials.password}</p>
                                <p><strong>Access Key ID:</strong> {iamCredentials.access_key_id}</p>
                                <p><strong>Secret Access Key:</strong> {iamCredentials.secret_access_key}</p>
                                <p>
                                    <strong>Sign in:</strong>{' '}
                                    <a href={`https://${iamCredentials.account_number}.signin.aws.amazon.com/console`} target="_blank" rel="noopener noreferrer">
                                        AWS Console
                                    </a>
                                </p>
                            </div>
                        )}

                        {iamError && <p className="error-message">{iamError}</p>}

                        <div className="lab-status">
                            {lab.instructions && (
                                <div className="instructions">
                                    <h4>Instructions:</h4>
                                    <ol className="instructions-list">
                                        {lab.instructions.split('\n').map((instruction, index) => (
                                            <li key={index}>{instruction}</li>
                                        ))}
                                    </ol>
                                </div>
                            )}
                            {lab.progress !== undefined && (
                                <div className="progress-container">
                                    <div className="progress-bar">
                                        <div className="progress-bar-fill" style={{ width: `${lab.progress}%` }} />
                                    </div>
                                    <p className="progress-text">{lab.progress}%</p>
                                </div>
                            )}
                        </div>
                    </>
                )}
                <Link to="/labs" className="back-btn">Back to Labs</Link>
            </div>
            {loading && <div className="loading-overlay"><div className="spinner"></div>Loading...</div>}
        </div>
    );
}

export default LabDetailPage;