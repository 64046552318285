import React, { useState } from 'react';
import './styles4.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './Login';
import EC2ControlPanel from './EC2ControlPanel';
import LabsPage from './LabsPage';
import MetricsPage from './MetricsPage';
import ReportPage from './ReportPage';
import LabDetailPage from './LabDetailPage';

function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLoginSuccess = (username, password) => {
    setUsername(username);
    setPassword(password);
    setAuthenticated(true);
  };

  const handleLogout = () => {
    setUsername('');
    setPassword('');
    setAuthenticated(false);
  };

  return (
    <Router>
      <div className="app-container">
        <header className="app-header">
          <h1>LabsKraft</h1>
        </header>
        <main className="app-main">
          <Routes>
            {/* Route for login page */}
            <Route path="/" element={
              !authenticated ? (
                <Login setAuthenticated={handleLoginSuccess} />
              ) : (
                <Navigate to="/control-panel" replace />
              )
            } />

            {/* Route for control panel */}
            <Route path="/control-panel" element={
              authenticated ? (
                <div className="control-panel">
                  <div className="user-info">
                    <h2>Welcome, {username}!</h2>
                  </div>
                  <EC2ControlPanel username={username} password={password} />
                  <button onClick={handleLogout} className="logout-btn">
                    Logout
                  </button>
                </div>
              ) : (
                <Navigate to="/" replace />
              )
            } />

            {/* Route for metrics page */}
            <Route path="/metrics" element={
              authenticated ? (
                <MetricsPage />
              ) : (
                <Navigate to="/MetricsPage" replace />
              )
            } />

            {/* Route for labs page */}
            <Route path="/labs" element={
              authenticated ? (
                <LabsPage username={username} platform="AWS" />
              ) : (
                <Navigate to="/" replace />
              )
            } />

            {/* Route for specific lab details */}
            <Route path="/labs/:labId" element={
              authenticated ? (
                <LabDetailPage username={username} />
              ) : (
                <Navigate to="/" replace />
              )
            } />

            {/* Route for report page */}
            <Route path="/report" element={
              authenticated ? (
                <ReportPage username={username} />
              ) : (
                <Navigate to="/" replace />
              )
            } />
          </Routes>
        </main>
        <footer className="app-footer">
          <p>© KloudKraft 2024 </p>
        </footer>
      </div>
    </Router>
  );
}

export default App;