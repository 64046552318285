import React, { useState } from 'react';
import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import './styles3.css';

const poolData = {
  UserPoolId: process.env.REACT_APP_USERPOOLID,
  ClientId: process.env.REACT_APP_CLIENTID,
};

const userPool = new CognitoUserPool(poolData);

function Login({ setAuthenticated }) {
  const [username, setLocalUsername] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [showNewPasswordField, setShowNewPasswordField] = useState(false);
  const [cognitoUser, setCognitoUser] = useState(null);
  const [userAttributes, setUserAttributes] = useState({});
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    const authenticationData = {
      Username: username,
      Password: password,
    };

    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = {
      Username: username,
      Pool: userPool,
    };

    const newCognitoUser = new CognitoUser(userData);
    setCognitoUser(newCognitoUser);

    newCognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        console.log('Login successful!');
        setAuthenticated(username, password);
        setLoading(false);
      },
      onFailure: (err) => {
        console.error(err.message || JSON.stringify(err));
        setError(err.message || 'Unknown error');
        setLoading(false);
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        setShowNewPasswordField(true);
        setError('You need to change your password and provide your name and email');
        setUserAttributes(userAttributes);
        setLoading(false);
      }
    });
  };

  const handleNewPasswordSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    if (!cognitoUser) {
      setError('Cognito user is not initialized');
      setLoading(false);
      return;
    }

    const attributesData = {
      ...userAttributes,
      name: name,
      email: email
    };

    delete attributesData.email_verified;
    delete attributesData.phone_number_verified;

    cognitoUser.completeNewPasswordChallenge(newPassword, attributesData, {
      onSuccess: (result) => {
        console.log('Password changed successfully');
        setAuthenticated(username, newPassword);
        setLoading(false);
      },
      onFailure: (err) => {
        console.error(err.message || JSON.stringify(err));
        setError(err.message || 'Failed to change password');
        setLoading(false);
      }
    });
  };

  return (
    <div className="login-container">
      {loading && <div className="loading-overlay"><div className="spinner"></div><p>Authenticating user</p></div>}
      <h2 className="login-title">Login</h2>
      {!showNewPasswordField ? (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setLocalUsername(e.target.value)}
              placeholder="Enter your username"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
              required
            />
          </div>
          {error && <div className="error-message">{error}</div>}
          <button type="submit" className="btn">Login</button>
        </form>
      ) : (
        <form onSubmit={handleNewPasswordSubmit}>
          <div className="form-group">
            <label htmlFor="newPassword">New Password</label>
            <input
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Enter your new password"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter your name"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
            />
          </div>
          {error && <div className="error-message">{error}</div>}
          <button type="submit" className="btn">Set New Password</button>
        </form>
      )}
    </div>
  );
}

export default Login;
