import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './ReportPage.css';

const API_URL = process.env.REACT_APP_API_URL; // Replace with your API endpoint

function ReportPage({ username }) {
  const [reportData, setReportData] = useState([]);
  const [message, setMessage] = useState(null);  // Update for handling message
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchReportData = async () => {
      try {
        const response = await fetch(`${API_URL}/QuotaReport?username=${username}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        
        if (data.message) {
          // If the API returns a message, set the message state
          setMessage(data.message);
        } else {
          setReportData(data);
        }
      } catch (error) {
        console.error('Error fetching report data:', error);
        setError('Error fetching report data: ' + error.message);
      }
    };

    fetchReportData();
  }, [username]);

  const handleBackToControlPanel = () => {
    navigate('/control-panel');
  };

  if (error) {
    return <div className="report-error">{error}</div>;
  }

  if (message) {
    return (
      <div className="no-data-message">
        <p>{message}</p>
        <button className="back-to-control-panel-btn" onClick={handleBackToControlPanel}>
          Back to EC2 Control Panel
        </button>
      </div>
    );
  }

  return (
    <div className="report-page">
      <h1>Report for {username}</h1>
      {reportData.length ? (
        reportData.map((item, index) => (
          <div key={index} className="report-card">
            <h2>Instance ID: {item.instanceId}</h2>
            <p><strong>Instance IP:</strong> {item.instanceIP}</p>
            <p><strong>Instance State:</strong> {item.instanceState}</p>
            <p><strong>Total Runtime (hours):</strong> {item.totalRunTimeHours.toFixed(2)}</p>
            <p><strong>Remaining Runtime (hours):</strong> {item.remainingRunTimeHours.toFixed(2)}</p>

            <div className="progress-container">
              <label><strong>Quota Used:</strong> {item.quotaUsedPercentage.toFixed(2)}%</label>
              <div className="progress-bar">
                <div
                  className="progress-bar-fill"
                  style={{ width: `${item.quotaUsedPercentage}%` }}
                ></div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p className="loading-message">Loading Report Data...</p>
      )}
      <button className="back-to-control-panel-btn" onClick={handleBackToControlPanel}>
        Back to EC2 Control Panel
      </button>
    </div>
  );
}

export default ReportPage;
