import React from 'react';
import { useNavigate } from 'react-router-dom';
import './metrics.css'; // Ensure this path is correct

const MetricsPage = () => {
  const navigate = useNavigate(); // Hook for navigation

  const handleBackClick = () => {
    navigate('/control-panel'); // Navigate back to the EC2 Control Panel
  };

  const metrics = [
    { src: 'https://ec2-control-panel.s3.us-west-1.amazonaws.com/CPU+utilization+(%25).png', title: 'CPU utilization (%)' },
    { src: 'https://ec2-control-panel.s3.us-west-1.amazonaws.com/Network+in+(bytes).png', title: 'Network in (bytes)' },
    { src: 'https://ec2-control-panel.s3.us-west-1.amazonaws.com/Network+out+(bytes).png', title: 'Network out (bytes)' },
    { src: 'https://ec2-control-panel.s3.us-west-1.amazonaws.com/Network+packets+in+(count).png', title: 'Network packets in (count)' },
    { src: 'https://ec2-control-panel.s3.us-west-1.amazonaws.com/Network+packets+out+(count).png', title: 'Network packets out (count)' },
    { src: 'https://ec2-control-panel.s3.us-west-1.amazonaws.com/CPU+credit+usage+(count).png', title: 'CPU credit usage (count)' },
  ];

  return (
    <div className="metrics-container">
      <div className="metrics-images">
        {metrics.map((metric, index) => (
          <div key={index} className="metric-item">
            <img src={metric.src} alt={`Metric ${index + 1}`} className="metric-image" />
            <div className="metric-title">{metric.title}</div>
          </div>
        ))}
      </div>
      <button onClick={handleBackClick} className="back-button">Back to EC2 Control Panel</button>
    </div>
  );
};

export default MetricsPage;
