// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import './styles6.css';

// const API_URL = 'https://lvzs4ckfvc.execute-api.us-west-1.amazonaws.com/prod6';

// function LabsPage({ username, platform }) {
//   const [selectedTask, setSelectedTask] = useState('');
//   const [labStatus, setLabStatus] = useState(null);
//   const [labCompleted, setLabCompleted] = useState(false);
//   const [iamCredentials, setIamCredentials] = useState(null);
//   const [iamError, setIamError] = useState(null);
//   const [isCheckingProgress, setIsCheckingProgress] = useState(false);
//   const [checkProgressDisabled, setCheckProgressDisabled] = useState(false);

//   const tasks = [
//     { id: 'task1', name: 'Create and Delete S3 Bucket' },
//     { id: 'task2', name: 'Create and Stop EC2 Instance' },
//     { id: 'task3', name: 'Create S3 Bucket, Add Object, and Delete' },
//   ];

//   useEffect(() => {
//     // Any necessary side effects here

//   }, []);

//   const startLab = async () => {
//     if (!selectedTask) {
//       alert('Please select a task before starting the lab.');
//       return;
//     }

//     try {
//       const response = await fetch(`${API_URL}/StartLab?username=${username}&taskId=${selectedTask}`);

//       if (!response.ok) {
//         throw new Error(`HTTP error! status: ${response.status}`);
//       }

//       const data = await response.json();
//       checkLabProgress(selectedTask);
//       requestIamCredentials();
//       setLabStatus(data);
//     } catch (error) {
//       console.error('Error starting lab:', error);
//       alert('Error starting lab: ' + error.message);
//     }
//   };

//   const checkLabProgress = async () => {
//     setIsCheckingProgress(true);
//     setCheckProgressDisabled(true);

//     try {
//       const response = await fetch(`${API_URL}/CheckLabProgress?username=${username}&taskId=${selectedTask}`);
//       if (!response.ok) {
//         throw new Error(`HTTP error! status: ${response.status}`);
//       }
//       const data = await response.json();
//       setLabStatus(data);

//       if (data.completed) {
//         setLabCompleted(true);
//       }
//     } catch (error) {
//       console.error('Error checking lab progress:', error);
//       alert('Error checking lab progress: ' + error.message);
//     } finally {
//       setIsCheckingProgress(false);
//       setTimeout(() => setCheckProgressDisabled(false), 30000); // Enable button after 1 minute
//     }
//   };

//   const requestIamCredentials = async () => {
//     try {
//       const response = await fetch(`${API_URL}/IAMCreds?username=${username}`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ queryStringParameters: { username } }),
//       });

//       if (!response.ok) {
//         const errorText = await response.text();
//         throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
//       }

//       const data = await response.json();
//       setIamCredentials(data);
//       setIamError(null);
//     } catch (error) {
//       console.error('Error requesting IAM credentials:', error);
//       setIamError('Error requesting IAM credentials: ' + error.message);
//       setIamCredentials(null);
//     }
//   };

//   return (
//     <div className="labs-page">
//       <h2>{platform} Labs</h2>
//       <div className="lab-container">
//         <div className="lab-selection">
//           <h3>Select a Lab</h3>
//           <select 
//             value={selectedTask} 
//             onChange={(e) => setSelectedTask(e.target.value)}
//             className="lab-select"
//           >
//             <option value="">Choose a lab task</option>
//             {tasks.map(task => (
//               <option key={task.id} value={task.id}>{task.name}</option>
//             ))}
//           </select>
//           <button onClick={startLab} className="start-lab-btn">Start Lab</button>
//           <button 
//             onClick={checkLabProgress} 
//             className="check-progress-btn" 
//             disabled={checkProgressDisabled}
//           >
//             Check Progress
//           </button>
//           {isCheckingProgress && (
//             <div className="progress-popup">Please wait...</div>
//           )}
//         </div>

//         {labStatus && (
//           <div className="lab-status">
//             <h3>Lab Status</h3>
//             <p><strong>Assigned Task:</strong> {labStatus.taskName}</p>
//             <p><strong>Completed:</strong> {labStatus.completed ? 'Yes' : 'No'}</p>
//             <div className="progress-container">
//               <div className="progress-bar">
//                 <div 
//                   className="progress-bar-fill" 
//                   style={{ width: `${labStatus.progress}%` }}
//                 />
//               </div>
//               <p className="progress-text">{labStatus.progress}%</p>
//             </div>
//             <div className="instructions">
//               <h4>Instructions:</h4>
//               <ol className="instructions-list">
//                 {labStatus.instructions && labStatus.instructions.split('\n').map((instruction, index) => (
//                   <li key={index}>{instruction}</li>
//                 ))}
//               </ol>
//             </div>
//           </div>
//         )}

//         {labCompleted && (
//           <div className="lab-completed">
//             <div className="icon">🎉</div>
//             <h2>Congratulations!</h2>
//             <p>Lab Completed Successfully!</p>
//           </div>
//         )}

//         {iamCredentials && (
//           <div className="iam-credentials">
//             <h3>IAM Credentials</h3>
//             <p><strong>Username:</strong> {iamCredentials.username}</p>
//             <p><strong>Password:</strong> {iamCredentials.password}</p>
//             <p><strong>Access Key:</strong> {iamCredentials.access_key_id}</p>
//             <p><strong>Secret Access Key:</strong> {iamCredentials.secret_access_key}</p>
//             <p>
//               <strong>Sign in:</strong>{' '}
//               <a href={`https://${iamCredentials.account_number}.signin.aws.amazon.com/console`} target="_blank" rel="noopener noreferrer">
//                 AWS Console
//               </a>
//             </p>
//           </div>
//         )}
//         {iamError && <p className="error-message">{iamError}</p>}
//       </div>
//       <Link to="/control-panel" className="back-btn">Back to Control Panel</Link>
//     </div>
//   );
// }

// export default LabsPage;
// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import './styles6.css';

// const API_URL = 'https://lvzs4ckfvc.execute-api.us-west-1.amazonaws.com/prod6';

// function LabsPage({ username, platform }) {
//   const [selectedTask, setSelectedTask] = useState('');
//   const [labStatus, setLabStatus] = useState(null);
//   const [labCompleted, setLabCompleted] = useState(false);
//   const [iamCredentials, setIamCredentials] = useState(null);
//   const [iamError, setIamError] = useState(null);
//   const [isCheckingProgress, setIsCheckingProgress] = useState(false);
//   const [checkProgressDisabled, setCheckProgressDisabled] = useState(false);

//   const tasks = [
//     { id: 'task1', name: 'Create and Delete S3 Bucket' },
//     { id: 'task2', name: 'Create and Stop EC2 Instance' },
//     { id: 'task3', name: 'Create S3 Bucket, Add Object, and Delete' },
//   ];

//   useEffect(() => {
//     // Any necessary side effects here

//   }, []);

//   const startLab = async () => {
//     if (!selectedTask) {
//       alert('Please select a task before starting the lab.');
//       return;
//     }

//     try {
//       const response = await fetch(`${API_URL}/StartLab?username=${username}&taskId=${selectedTask}`);

//       if (!response.ok) {
//         throw new Error(`HTTP error! status: ${response.status}`);
//       }

//       const data = await response.json();
//       checkLabProgress(selectedTask);
//       requestIamCredentials();
//       setLabStatus(data);
//     } catch (error) {
//       console.error('Error starting lab:', error);
//       alert('Error starting lab: ' + error.message);
//     }
//   };

//   const checkLabProgress = async () => {
//     setIsCheckingProgress(true);
//     setCheckProgressDisabled(true);

//     try {
//       const response = await fetch(`${API_URL}/CheckLabProgress?username=${username}&taskId=${selectedTask}`);
//       if (!response.ok) {
//         throw new Error(`HTTP error! status: ${response.status}`);
//       }
//       const data = await response.json();
//       setLabStatus(data);

//       if (data.completed) {
//         setLabCompleted(true);
//       }
//     } catch (error) {
//       console.error('Error checking lab progress:', error);
//       alert('Error checking lab progress: ' + error.message);
//     } finally {
//       setIsCheckingProgress(false);
//       setTimeout(() => setCheckProgressDisabled(false), 30000); // Enable button after 1 minute
//     }
//   };

//   const requestIamCredentials = async () => {
//     try {
//       const response = await fetch(`${API_URL}/IAMCreds?username=${username}`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ queryStringParameters: { username } }),
//       });

//       if (!response.ok) {
//         const errorText = await response.text();
//         throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
//       }

//       const data = await response.json();
//       setIamCredentials(data);
//       setIamError(null);
//     } catch (error) {
//       console.error('Error requesting IAM credentials:', error);
//       setIamError('Error requesting IAM credentials: ' + error.message);
//       setIamCredentials(null);
//     }
//   };

//   return (
//     <div className="labs-page">
//       <h2>{platform} Labs</h2>
//       <div className="lab-container">
//         <div className="lab-selection">
//           <h3>Select a Lab</h3>
//           <select 
//             value={selectedTask} 
//             onChange={(e) => setSelectedTask(e.target.value)}
//             className="lab-select"
//           >
//             <option value="">Choose a lab task</option>
//             {tasks.map(task => (
//               <option key={task.id} value={task.id}>{task.name}</option>
//             ))}
//           </select>
//           <button onClick={startLab} className="start-lab-btn">Start Lab</button>
//           <button 
//             onClick={checkLabProgress} 
//             className="check-progress-btn" 
//             disabled={checkProgressDisabled}
//           >
//             Check Progress
//           </button>
//           {isCheckingProgress && (
//             <div className="progress-popup">Please wait...</div>
//           )}
//         </div>

//         {labStatus && (
//           <div className="lab-status">
//             <h3>Lab Status</h3>
//             <p><strong>Assigned Task:</strong> {labStatus.taskName}</p>
//             <p><strong>Completed:</strong> {labStatus.completed ? 'Yes' : 'No'}</p>
//             <div className="progress-container">
//               <div className="progress-bar">
//                 <div 
//                   className="progress-bar-fill" 
//                   style={{ width: `${labStatus.progress}%` }}
//                 />
//               </div>
//               <p className="progress-text">{labStatus.progress}%</p>
//             </div>
//             <div className="instructions">
//               <h4>Instructions:</h4>
//               <ol className="instructions-list">
//                 {labStatus.instructions && labStatus.instructions.split('\n').map((instruction, index) => (
//                   <li key={index}>{instruction}</li>
//                 ))}
//               </ol>
//             </div>
//           </div>
//         )}

//         {labCompleted && (
//           <div className="lab-completed">
//             <div className="icon">🎉</div>
//             <h2>Congratulations!</h2>
//             <p>Lab Completed Successfully!</p>
//           </div>
//         )}

//         {iamCredentials && (
//           <div className="iam-credentials">
//             <h3>IAM Credentials</h3>
//             <p><strong>Username:</strong> {iamCredentials.username}</p>
//             <p><strong>Password:</strong> {iamCredentials.password}</p>
//             <p><strong>Access Key:</strong> {iamCredentials.access_key_id}</p>
//             <p><strong>Secret Access Key:</strong> {iamCredentials.secret_access_key}</p>
//             <p>
//               <strong>Sign in:</strong>{' '}
//               <a href={`https://${iamCredentials.account_number}.signin.aws.amazon.com/console`} target="_blank" rel="noopener noreferrer">
//                 AWS Console
//               </a>
//             </p>
//           </div>
//         )}
//         {iamError && <p className="error-message">{iamError}</p>}
//       </div>
//       <Link to="/control-panel" className="back-btn">Back to Control Panel</Link>
//     </div>
//   );
// }

// export default LabsPage;
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './styles6.css'; // Import the CSS file for styling

const API_URL = 'https://lvzs4ckfvc.execute-api.us-west-1.amazonaws.com/prod1/GetLabs';

function LabsPage({ username, platform }) {
    const [labs, setLabs] = useState([]);

    useEffect(() => {
        // Fetch labs
        const fetchLabs = async () => {
            try {
                const response = await fetch(`${API_URL}?username=${username}`);
                if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
                const data = await response.json();
                setLabs(data);
            } catch (error) {
                console.error('Error fetching labs:', error);
            }
        };

        fetchLabs();
    }, [username, platform]);

    return (
        <div className="labs-page">
            <h2>Available Labs</h2>
            <div className="labs-grid">
                {labs.map(lab => (
                    <div key={lab.taskId} className="lab-card">
                        <h3>{lab.taskName}</h3>
                        <Link 
                            to={`/labs/${lab.taskId}?taskName=${encodeURIComponent(lab.taskName)}`} 
                            className="view-details-btn"
                        >
                            View Details
                        </Link>
                    </div>
                ))}
            </div>
            <Link to="/control-panel" className="back-to-ec2-btn">
                Back to EC2 Control Panel
            </Link>
        </div>
    );
}

export default LabsPage;
